<template>
  <div id="specs_tests" class="specs-tests">
    <div class="specs-tests__header">
      <div class="specs-tests__title">Тесты по специализации</div>
      <component
        v-if="!mediaQueries.mobile"
        :is="testCanBeRequested ? 'div' : 'BasicTooltip'"
        :content="tooltipText"
        :max-width="225"
        placement="top"
        theme="dark"
      >
        <WithSpinnerPlaceholder :loading="isAvailableTestsLoading">
          <BasicButton
            type="button"
            appearance="secondary"
            size="m"
            :disabled="!testCanBeRequested"
            @click="openSpecsTestsModal"
          >
            {{ buttonText }}
          </BasicButton>
        </WithSpinnerPlaceholder>
      </component>
    </div>
    <WithSpinnerPlaceholder
      v-if="currentUserTests.length"
      :loading="isUserTestsLoading"
      :size="72"
      color="light-blue"
    >
      <div class="specs-tests__list">
        <SpecsTestsItem
          v-for="(test, index) in formattedUsersTests"
          :key="index"
          :is-owner="isOwner"
          :test="test"
        />
      </div>
      <div
        v-if="currentUserTests.length > 3 && !showAll"
        class="specs-tests__show_all"
      >
        <BasicButton appearance="pseudo-link-bold" @click="showAll = true">
          Показать все
        </BasicButton>
      </div>
    </WithSpinnerPlaceholder>
    <div v-else class="specs-tests__empty">Нет пройденных тестов</div>
    <component
      v-if="mediaQueries.mobile"
      :is="testCanBeRequested ? 'div' : 'BasicTooltip'"
      :content="tooltipText"
      :max-width="225"
      placement="top"
      theme="dark"
    >
      <WithSpinnerPlaceholder :loading="isAvailableTestsLoading">
        <BasicButton
          type="button"
          appearance="secondary"
          size="m"
          :disabled="!testCanBeRequested"
          @click="openSpecsTestsModal"
          full-width
          class="specs-tests__button"
        >
          {{ buttonText }}
        </BasicButton>
      </WithSpinnerPlaceholder>
    </component>
    <SpecsTestsModal
      v-if="showSpecsTestsModal"
      :is-owner="isOwner"
      :available-tests="availableTests"
      :is-loading="isRequestTestLoading"
      @close="showSpecsTestsModal = false"
      @sendTest="sendTest"
    />
    <DecoratedResultModal
      v-if="showTestSentModal"
      @close="showTestSentModal = false"
      appearance="success"
      title="Отправили тест кандидату"
    >
      <template #default>
        <p>
          Пришлём сообщение на Хабр Карьере, как только специалист его пройдёт.
        </p>
      </template>

      <template #actions>
        <ButtonGroup appearance="inline">
          <BasicButton
            v-if="user.conversation"
            type="button"
            appearance="primary"
            size="l"
            :href="user.conversation.href"
          >
            К диалогу
          </BasicButton>
          <BasicButton
            type="button"
            appearance="secondary"
            size="l"
            @click="showTestSentModal = false"
          >
            Закрыть
          </BasicButton>
        </ButtonGroup>
      </template>
    </DecoratedResultModal>
  </div>
</template>

<script>
import { notify } from 'src/notify.js';
import { handleRequestError } from 'src/services/api.js';
import { bem } from 'src/utils/bem.js';

import BasicButton from 'src/components/BasicButton/BasicButton.vue';
import BasicTooltip from 'src/components/BasicTooltip/BasicTooltip.vue';
import ButtonGroup from 'src/components/ButtonGroup/ButtonGroup.vue';
import DecoratedResultModal from 'src/components/DecoratedResultModal/DecoratedResultModal.vue';
import WithSpinnerPlaceholder from 'src/components/placeholders/WithSpinnerPlaceholder/WithSpinnerPlaceholder.vue';
import SpecsTestsItem from 'src/components/tests/SpecsTestsItem/SpecsTestsItem.vue';
import SpecsTestsModal from 'src/components/tests/SpecsTestsModal/SpecsTestsModal.vue';

import { TestsResource } from 'src/services/tests';

export default {
  name: 'SpecsTests',
  components: {
    WithSpinnerPlaceholder,
    ButtonGroup,
    BasicTooltip,
    BasicButton,
    SpecsTestsModal,
    DecoratedResultModal,
    SpecsTestsItem,
  },
  inject: ['mediaQueries'],
  props: {
    userTests: {
      type: Array,
      required: true,
    },
    availableSuggest: {
      type: Boolean,
      required: true,
    },
    isOwner: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentUserTests: this.userTests,
      showAll: false,
      showSpecsTestsModal: false,
      showTestSentModal: false,
      availableTests: null,
      isAvailableTestsLoading: false,
      isUserTestsLoading: false,
      isRequestTestLoading: false,
    };
  },
  methods: {
    bem,
    async sendTest(test) {
      if (!this.testCanBeRequested) {
        return;
      }
      const request = {
        kind: test.provider.kind,
        title: test.title,
        user: this.user.id,
      };
      try {
        this.isRequestTestLoading = true;
        const response = await TestsResource.requestTest({ request });
        if (this.isOwner) {
          const url = `${window.location.origin}/tests/specs/${response.data.scoringId}`;
          window.open(url, '_blank');
        } else {
          this.showTestSentModal = true;
          window.helpers.trackEvent('test', 'send');
        }
        this.showSpecsTestsModal = false;
        await this.getUserTests();
      } catch (error) {
        if (
          error.isAxiosError &&
          error.response?.status === 400 &&
          error.response?.data?.message
        ) {
          notify({ message: error.response?.data?.message, type: 'error' });
        } else {
          handleRequestError(error);
        }
      } finally {
        this.isRequestTestLoading = false;
      }
    },
    async getUserTests() {
      try {
        this.isUserTestsLoading = true;
        const response = await TestsResource.getUserTests(this.user.id);
        this.currentUserTests = response.data.list;
      } catch (error) {
        handleRequestError(error);
      } finally {
        this.isUserTestsLoading = false;
      }
    },
    async getAvailableTests() {
      try {
        this.isAvailableTestsLoading = true;
        const response = await TestsResource.getAvailableTests(this.user.id);
        this.availableTests = response.data.list;
      } catch (error) {
        handleRequestError(error);
      } finally {
        this.isAvailableTestsLoading = false;
      }
    },
    async openSpecsTestsModal() {
      await this.getAvailableTests();
      this.showSpecsTestsModal = true;
    },
  },

  computed: {
    formattedUsersTests() {
      if (this.showAll) return this.currentUserTests;
      return this.currentUserTests.slice(0, 3);
    },
    buttonText() {
      return this.isOwner ? 'Пройти тест' : 'Предложить пройти тест';
    },
    isBlocked() {
      return this.user.jobInviteButton?.state.startsWith('blocked_');
    },
    testCanBeRequested() {
      return this.availableSuggest && !this.isBlocked;
    },
    tooltipText() {
      if (!this.availableSuggest) return 'Нет доступных тестов';
      switch (this.user.jobInviteButton?.state) {
        case 'blocked_by_user':
          return 'Вы не можете попросить пройти тест этого пользователя, так как он вас заблокировал';
        case 'blocked_by_you':
          return 'Вы не можете попросить пройти тест этого пользователя, так как вы его заблокировали';
        default:
          return null;
      }
    },
  },
};
</script>
