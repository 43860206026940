<template>
  <div :title="settingsText" class="visibility-settings">
    <SvgIcon v-if="isHidden" icon="crossed-eye" size="24" color="ui-gray" />
    <SvgIcon v-else icon="eye" size="24" color="ui-gray" />
  </div>
</template>

<script>
import SvgIcon from 'src/components/icons/SvgIcon/SvgIcon.vue';

export default {
  name: 'VisibilitySettings',
  components: {
    SvgIcon,
  },
  props: {
    isHidden: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    settingsText() {
      return this.isHidden ? 'Видно только вам' : 'Видно всем';
    },
  },
};
</script>
