<template>
  <ModalWindow
    appearance="new"
    title="Тесты по специализации"
    is-content-scrollable
    @close="$emit('close')"
  >
    <div class="specs-tests-modal">
      <div v-if="!isOwner" class="specs-tests-modal__description">
        Предложите специалисту пройти тест, чтобы оценить его уровень. Это
        сократит время тимлидов на собеседования и снизит стоимость найма
        <a
          target="_blank"
          href="https://www.cnews.ru/news/line/2024-04-01_v_63_rossijskih_it-kompanij"
          >в два раза</a
        >.
      </div>
      <div v-if="!availableTests.length" class="specs-tests-modal-test__empty">
        Нет доступных тестов
      </div>
      <template v-else>
        <WithSpinnerPlaceholder
          v-if="isLoading"
          :loading="isLoading"
          :size="72"
          color="light-blue"
          class="specs-tests-modal-test__placeholder"
        />
        <template v-else>
          <div
            v-for="(test, index) in availableTests"
            :key="index"
            class="specs-tests-modal-test"
          >
            <div class="specs-tests-modal-test__provider">
              <img
                :src="test.provider.logo.src"
                class="specs-tests-modal-test__provider-icon"
                alt=""
              />
              <div class="specs-tests-modal-test__provider-name">
                {{ test.provider.name }}
              </div>
            </div>
            <div class="specs-tests-modal-test__name">{{ test.title }}</div>
            <BasicButton
              type="button"
              appearance="secondary"
              size="m"
              @click="$emit('sendTest', test)"
              full-width
              class="specs-tests-modal-test__button"
            >
              {{ isOwner ? 'Пройти' : 'Предложить пройти' }}
            </BasicButton>
          </div>
        </template>
      </template>
    </div>
    <template #footer>
      <div class="specs-tests-modal__footer">
        <BasicButton
          type="button"
          appearance="secondary"
          size="l"
          @click="$emit('close')"
        >
          Закрыть
        </BasicButton>
      </div>
    </template>
  </ModalWindow>
</template>

<script>
import { bem } from 'src/utils/bem.js';

import BasicButton from 'src/components/BasicButton/BasicButton.vue';
import ModalWindow from 'src/components/overlays/ModalWindow/ModalWindow.vue';
import WithSpinnerPlaceholder from 'src/components/placeholders/WithSpinnerPlaceholder/WithSpinnerPlaceholder.vue';

export default {
  name: 'SpecsTestsModal',
  components: {
    WithSpinnerPlaceholder,
    ModalWindow,
    BasicButton,
  },
  props: {
    isOwner: {
      type: Boolean,
      default: true,
    },
    availableTests: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'sendTest'],
  methods: {
    bem,
  },
};
</script>
