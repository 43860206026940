<template>
  <ModalWindow
    appearance="new"
    :title="testResult.title"
    is-content-scrollable
    @close="$emit('close')"
  >
    <div
      class="specs-test-result-modal"
      :class="bem('specs-test-result-modal', { status: testResult.status })"
    >
      <div class="specs-test-result-modal__block">
        <VisibilitySettings
          v-if="isOwner && testResult.status !== 'sent'"
          class="specs-test-result-modal__visibility"
          :is-hidden="testResult.hidden"
        />
        <div
          v-if="statusName"
          class="specs-test-result-modal__status"
          :class="
            bem('specs-test-result-modal__status', {
              status: testResult.status,
            })
          "
        >
          {{ statusName }}
        </div>
        <div class="specs-test-result-modal__provider">
          <img
            :src="testResult.provider.logo.src"
            class="specs-test-result-modal__provider-icon"
            alt=""
          />
          <div class="specs-test-result-modal__provider-name">
            {{ testResult.provider.name }}
          </div>
        </div>
        <template
          v-if="
            testResult.status === 'passed' || testResult.status === 'expired'
          "
        >
          <div class="specs-test-result-modal__date">
            {{ finishDate }}
          </div>
          <component
            v-if="isOwner"
            :is="canPassAgain ? 'div' : 'BasicTooltip'"
            :content="nextAttemptDate"
            placement="top"
            :max-width="240"
            theme="dark"
          >
            <BasicButton
              type="button"
              appearance="secondary"
              size="m"
              :disabled="!canPassAgain"
              full-width
              class="specs-test-result-modal__button"
            >
              Пройти заново
            </BasicButton>
          </component>
        </template>
        <template v-else-if="isOwner">
          <BasicButton
            type="button"
            :href="testResult.link"
            appearance="secondary"
            size="m"
            full-width
            class="specs-test-result-modal__button"
          >
            Пройти
          </BasicButton>
        </template>
        <div v-else class="specs-test-result-modal__date">
          {{ sentDate }}
        </div>
      </div>

      <WithSpinnerPlaceholder
        v-if="testResult.status !== 'sent'"
        :loading="isReportLoading"
        class="specs-test-result-modal__block"
      >
        <div class="specs-test-result-modal__report">
          <div class="specs-test-result-modal__report-name">
            Подробный отчет
          </div>
          <div v-if="!hasReport" class="specs-test-result-modal__report-date">
            {{ endOfFreePeriodDate }}
          </div>
        </div>
        <BasicButton
          v-if="!hasReport"
          type="button"
          appearance="green-primary"
          size="m"
          full-width
          @click="getReport"
        >
          <span
            >Получить отчёт за 0 ₽
            <span class="specs-test-result-modal__price">2500 ₽</span></span
          >
        </BasicButton>
        <BasicButton
          v-else
          type="button"
          appearance="primary"
          size="m"
          full-width
          @click="openReport"
        >
          Посмотреть отчёт
        </BasicButton>
      </WithSpinnerPlaceholder>
    </div>
    <template #footer>
      <div class="specs-test-result-modal__footer">
        <BasicButton appearance="secondary" size="l" @click="$emit('close')">
          Закрыть
        </BasicButton>
      </div>
    </template>
  </ModalWindow>
</template>

<script>
import { handleRequestError } from 'src/services/api.js';
import { bem } from 'src/utils/bem.js';
import { formatDate } from 'src/utils/datetime.js';

import BasicButton from 'src/components/BasicButton/BasicButton.vue';
import BasicTooltip from 'src/components/BasicTooltip/BasicTooltip.vue';
import ModalWindow from 'src/components/overlays/ModalWindow/ModalWindow.vue';
import WithSpinnerPlaceholder from 'src/components/placeholders/WithSpinnerPlaceholder/WithSpinnerPlaceholder.vue';
import VisibilitySettings from 'src/components/tests/VisibilitySettings/VisibilitySettings.vue';

import { TestsResource } from 'src/services/tests';

export default {
  name: 'SpecsTestResultModal',
  components: {
    VisibilitySettings,
    WithSpinnerPlaceholder,
    ModalWindow,
    BasicButton,
    BasicTooltip,
  },
  inject: ['mediaQueries'],
  props: {
    testResult: {
      type: Object,
      required: true,
    },
    isOwner: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      hasReport: false,
      isReportLoading: false,
      reportLink: null,
    };
  },
  methods: {
    bem,
    async getReport() {
      try {
        this.isReportLoading = true;
        const response = await TestsResource.getTestReport(
          this.testResult.scoringId,
        );
        this.reportLink = response.data.link;
        this.hasReport = true;
        window.helpers.trackEvent('take', 'result');
      } catch (error) {
        handleRequestError(error);
      } finally {
        this.isReportLoading = false;
      }
    },
    openReport() {
      window.helpers.trackEvent('download', 'result');
      window.open(this.reportLink, '_blank');
    },
  },

  computed: {
    statusName() {
      switch (this.testResult.status) {
        case 'passed':
          return 'Пройден';

        case 'expired':
          return 'Пройден, данные устарели';

        default:
          return '';
      }
    },
    finishDate() {
      const formatFinishDate = formatDate(this.testResult.finishedAt);
      return `Тест от ${formatFinishDate}`;
    },
    sentDate() {
      const formatSentDate = formatDate(this.testResult.sentAt);
      return `Отправлен ${formatSentDate}`;
    },
    endOfFreePeriodDate() {
      const formatEndOfFreePeriodDate = formatDate(
        this.testResult.endOfFreePeriod,
      );
      return `до ${formatEndOfFreePeriodDate}`;
    },
    canPassAgain() {
      const nextAttemptDate = new Date(this.testResult.finishedAt);
      nextAttemptDate.setDate(nextAttemptDate.getDate() + 30);
      return nextAttemptDate < new Date();
    },
    nextAttemptDate() {
      const nextAttemptDate = new Date(this.testResult.finishedAt);
      nextAttemptDate.setDate(nextAttemptDate.getDate() + 30);
      const formatNextAttemptDate = formatDate(nextAttemptDate);
      return `Вы сможете заново пройти тест ${formatNextAttemptDate}`;
    },
  },
};
</script>
