const cases = [2, 0, 1, 1, 1, 2];

/**
 * Выбирает нужное склонение в зависимости от числа
 * В Slim-шаблонах можно использовать `Russian.pluralize`
 * @param {string} one
 * @param {string} two
 * @param {string} many
 * @returns {function(number): string}
 */
export function getRussianPluralDeclension(one, two, many) {
  const titles = [one, two, many];
  return (number) => {
    const rounded = Math.abs(number);

    return titles[
      rounded % 100 > 4 && rounded % 100 < 20
        ? 2
        : cases[rounded % 10 < 5 ? rounded % 10 : 5]
    ];
  };
}

export const getFormattedNumber = (number, locale = 'ru-RU') => {
  return new Intl.NumberFormat(locale).format(number);
};
