import { apiUrlV1, cancellableRequest } from 'src/services/api.js';

const mockData = false;
function makeDelay() {
  return Math.floor(Math.random() * (400 - 200 + 1)) + 200;
}

function makeFakeRequest({ data }) {
  return new Promise((resolve) => {
    setTimeout(() => resolve({ data }), makeDelay());
  });
}

const getTests = () => {
  return {
    list: [
      {
        provider: {
          name: 'Jumse',
          kind: 'jumse',
          logo: {
            src:
              'https://habrastorage.org/getpro/moikrug/uploads/education_platform/000/000/058/logo/medium_90c46342f487198001713f1faa9f8eee.png',
          },
        },
        title: 'Python',
      },
      {
        provider: {
          name: 'Jumse',
          kind: 'jumse',
          logo: {
            src:
              'https://habrastorage.org/getpro/moikrug/uploads/education_platform/000/000/058/logo/medium_90c46342f487198001713f1faa9f8eee.png',
          },
        },
        title: 'JS+TS Senior Sngl',
      },
      {
        provider: {
          name: 'Jumse',
          kind: 'jumse',
          logo: {
            src:
              'https://habrastorage.org/getpro/moikrug/uploads/education_platform/000/000/058/logo/medium_90c46342f487198001713f1faa9f8eee.png',
          },
        },
        title: 'Java Core',
      },
      {
        provider: {
          name: 'Jumse',
          kind: 'jumse',
          logo: {
            src:
              'https://habrastorage.org/getpro/moikrug/uploads/education_platform/000/000/058/logo/medium_90c46342f487198001713f1faa9f8eee.png',
          },
        },
        title: 'Java',
      },
    ],
  };
};

const getTestResult = (id) => {
  if (id === '123') {
    return {
      title: 'JS+TS Senior Sngl',
      status: 'sent',
      sentAt: '2025-01-10',
      finishedAt: '2025-01-21',
      endOfFreePeriod: '2025-01-21',
      link: 'https://gohub.jumse.ru/?t=00000',
      sentByMe: false, // этот флаг согласовать
      provider: {
        name: 'Jumse',
        logo: {
          src:
            'https://habrastorage.org/getpro/moikrug/uploads/education_platform/000/000/058/logo/medium_90c46342f487198001713f1faa9f8eee.png',
        },
      },
    };
  }
  if (id === '234') {
    return {
      title: 'Java Core',
      status: 'expired',
      sentAt: '2025-01-10',
      finishedAt: '2025-01-21',
      endOfFreePeriod: '2025-01-21',
      link: 'https://gohub.jumse.ru/?t=00000',
      sentByMe: false, // этот флаг согласовать
      provider: {
        name: 'Jumse',
        logo: {
          src:
            'https://habrastorage.org/getpro/moikrug/uploads/education_platform/000/000/058/logo/medium_90c46342f487198001713f1faa9f8eee.png',
        },
      },
    };
  }
  if (id === '345') {
    return {
      title: 'JPython',
      status: 'passed',
      sentAt: '2025-01-10',
      finishedAt: '2025-01-21',
      endOfFreePeriod: '2025-01-21',
      link: 'https://gohub.jumse.ru/?t=00000',
      sentByMe: false, // этот флаг согласовать
      provider: {
        name: 'Jumse',
        logo: {
          src:
            'https://habrastorage.org/getpro/moikrug/uploads/education_platform/000/000/058/logo/medium_90c46342f487198001713f1faa9f8eee.png',
        },
      },
    };
  }
  return {
    title: 'Python',
    status: 'passed',
    sentAt: '2025-01-10',
    finishedAt: '2025-01-21',
    endOfFreePeriod: '2025-01-21',
    link: 'https://gohub.jumse.ru/?t=00000',
    sentByMe: false, // этот флаг согласовать
    provider: {
      name: 'Jumse',
      logo: {
        src:
          'https://habrastorage.org/getpro/moikrug/uploads/education_platform/000/000/058/logo/medium_90c46342f487198001713f1faa9f8eee.png',
      },
    },
  };
};

const getUserTests = () => {
  return {
    list: [
      {
        scoringId: '123',
        title: 'JS+TS Senior Sngl',
        status: 'sent',
        provider: {
          name: 'Jumse',
          logo: {
            src:
              'https://habrastorage.org/getpro/moikrug/uploads/education_platform/000/000/058/logo/medium_90c46342f487198001713f1faa9f8eee.png',
          },
        },
        sentByMe: false, // этот флаг согласовать
      },
      {
        scoringId: '234',
        title: 'Java Core',
        status: 'expired',
        provider: {
          name: 'Jumse',
          logo: {
            src:
              'https://habrastorage.org/getpro/moikrug/uploads/education_platform/000/000/058/logo/medium_90c46342f487198001713f1faa9f8eee.png',
          },
        },
      },
      {
        scoringId: '345',
        title: 'JPython',
        status: 'passed',
        provider: {
          name: 'Jumse',
          logo: {
            src:
              'https://habrastorage.org/getpro/moikrug/uploads/education_platform/000/000/058/logo/medium_90c46342f487198001713f1faa9f8eee.png',
          },
        },
      },
      {
        scoringId: '456',
        title: 'Python',
        status: 'passed',
        provider: {
          name: 'Jumse',
          logo: {
            src:
              'https://habrastorage.org/getpro/moikrug/uploads/education_platform/000/000/058/logo/medium_90c46342f487198001713f1faa9f8eee.png',
          },
        },
      },
    ],
    availableSuggest: true,
    allHidden: true,
  };
};

export const TestsResource = {
  getAvailableTests(userAlias) {
    if (mockData) {
      return makeFakeRequest({ data: getTests() });
    }

    return cancellableRequest({
      method: 'get',
      url: `${apiUrlV1}/scoring/tests`,
      params: {
        login: userAlias,
      },
    });
  },
  getUserTests(userAlias) {
    if (mockData) {
      return makeFakeRequest({ data: getUserTests() });
    }

    return cancellableRequest({
      method: 'get',
      url: `${apiUrlV1}/scoring`,
      params: {
        user: userAlias,
      },
    });
  },

  /**
   * @param {{
   *   request: {
   *     kind: string,
   *        title: string,
   *        user: string,
   *   }
   * }} params
   */
  requestTest(params) {
    if (mockData) {
      return makeFakeRequest({ data: { status: 'OK', scoring_id: '123456' } });
    }

    return cancellableRequest({
      method: 'post',
      url: `${apiUrlV1}/scoring/request`,
      data: params,
    });
  },
  getTestResult(testId) {
    if (mockData) {
      return makeFakeRequest({ data: { attempt: getTestResult(testId) } });
    }

    return cancellableRequest({
      method: 'get',
      url: `${apiUrlV1}/scoring/${testId}/result`,
    });
  },
  getTestReport(testId) {
    if (mockData) {
      return makeFakeRequest({ data: getTestResult(testId) });
    }

    return cancellableRequest({
      method: 'get',
      url: `${apiUrlV1}/scoring/${testId}/report`,
    });
  },
};
