const withLeadingZero = (number) => number.toString().padStart(2, '0');
/**
 * @param {Date} date
 * @return {string}
 */
export function extractDate(date) {
  return `${date.getFullYear()}-${withLeadingZero(
    date.getMonth() + 1,
  )}-${withLeadingZero(date.getDate())}`;
}

const parts = {
  year: {
    getter: Date.prototype.getFullYear,
    setter: Date.prototype.setFullYear,
  },
  month: {
    getter: Date.prototype.getMonth,
    setter: Date.prototype.setMonth,
  },
  date: {
    getter: Date.prototype.getDate,
    setter: Date.prototype.setDate,
  },
};

function constrainDatePart(date, constraint, part, greater) {
  const { getter, setter } = parts[part];
  const value = getter.apply(date);
  const constraintValue = getter.call(constraint);

  const shouldConstrain = greater
    ? value > constraintValue
    : value < constraintValue;
  if (shouldConstrain) {
    setter.call(date, constraintValue);
  }
}
/**
 * Без деструктуризации рейнжа, потому что в консоли появляется ошибка
 * TypeError: Invalid attempt to destructure non-iterable instance.
 * In order to be iterable, non-array objects must have a [Symbol.iterator]() method.
 * При этом активных брейкпоинтах она не появляется.
 *
 * @param {Date|null} date
 * @param {Date} minDate
 * @param {Date} maxDate
 */
export function constrainDate(date, minDate, maxDate) {
  if (!date) return;
  if (date >= minDate && date <= maxDate) return;

  const greater = date > maxDate;
  const constraint = greater ? maxDate : minDate;

  Object.keys(parts).forEach((part) => {
    constrainDatePart(date, constraint, part, greater);
  });
}

export function formatResponseTime(sec) {
  const days = Math.floor(sec / (24 * 60 * 60));
  const hours = Math.floor((sec % (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.round((sec % (60 * 60)) / 60);

  const daysString = days ? `${days}д ` : '';
  const hoursString = hours ? `${hours}ч ` : '';
  const minutesString = minutes ? `${minutes}мин` : '';

  if (!days && !hours && !minutes) return '0мин';

  return `${daysString}${hoursString}${minutesString}`;
}

/**
 * Возвращает название месяц в три символа с большой или маленькой буквы, например 'Окт', 'сен'
 * @param {string} date
 * @param {boolean} isUpperCase
 */
export function formatMonth(date, isUpperCase = false) {
  const dateObject = new Date(date);
  const month = dateObject.toLocaleString('ru', { month: 'short' });

  if (!isUpperCase) return month.slice(0, 3);
  return `${month.charAt(0).toUpperCase()}${month.slice(1, 3)}`;
}

/**
 * Возвращает дату в формате '1 сен 2024'
 * @param {string} date
 */
export function formatDate(date) {
  const jsDate = new Date(date);
  const dayNumber = jsDate.getDate();
  const month = formatMonth(date);
  const yearNumber = jsDate.getFullYear();
  return `${dayNumber} ${month} ${yearNumber}`;
}
