<template>
  <div class="specs-tests-item">
    <img :src="test.provider.logo.src" class="specs-tests-item__icon" alt="" />
    <div class="specs-tests-item__block">
      <WithSpinnerPlaceholder :loading="isResultLoading">
        <div class="specs-tests-item__name" @click="openTestResult(test)">
          Тест «{{ test.title }}»
        </div>
      </WithSpinnerPlaceholder>
      <div
        class="specs-tests-item__status"
        :class="bem('specs-tests-item__status', { status: test.status })"
      >
        {{ statusName }}
      </div>
    </div>
    <div class="specs-tests-item__additional">
      <template v-if="test.company">
        <a
          class="specs-tests-item__company"
          :href="test.company.link"
          target="_blank"
        >
          {{ test.company.title }}
        </a>
      </template>
      <div class="specs-tests-item__date">
        {{ testDate }}
      </div>
      <VisibilitySettings
        v-if="isOwner && test.status !== 'sent'"
        class="specs-tests-item__visibility"
        :is-hidden="test.hidden"
      />
    </div>
    <SpecsTestResultModal
      v-if="showTestResultModal"
      :is-owner="isOwner"
      :test-result="testResult"
      @close="showTestResultModal = false"
    />
  </div>
</template>

<script>
import { handleRequestError } from 'src/services/api.js';
import { bem } from 'src/utils/bem.js';
import { formatDate } from 'src/utils/datetime.js';

import WithSpinnerPlaceholder from 'src/components/placeholders/WithSpinnerPlaceholder/WithSpinnerPlaceholder.vue';
import SpecsTestResultModal from 'src/components/tests/SpecsTestResultModal/SpecsTestResultModal.vue';
import VisibilitySettings from 'src/components/tests/VisibilitySettings/VisibilitySettings.vue';

import { TestsResource } from 'src/services/tests';

export default {
  name: 'SpecsTestsItem',
  components: {
    VisibilitySettings,
    WithSpinnerPlaceholder,
    SpecsTestResultModal,
  },
  inject: ['mediaQueries'],
  props: {
    test: {
      type: Object,
      required: true,
    },
    isOwner: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showTestResultModal: false,
      testResult: null,
      isResultLoading: false,
    };
  },
  methods: {
    bem,
    async openTestResult(test) {
      try {
        this.isResultLoading = true;
        const response = await TestsResource.getTestResult(test.scoringId);
        this.testResult = response.data;
        this.showTestResultModal = true;
      } catch (error) {
        handleRequestError(error);
      } finally {
        this.isResultLoading = false;
      }
    },
  },

  computed: {
    // eslint-disable-next-line complexity
    statusName() {
      switch (this.test.status) {
        case 'passed':
          return 'Пройден';

        case 'sent':
          if (this.isOwner) return 'Ожидает прохождения';
          return this.test.sentBy === 'current_user'
            ? 'Вы отправили тест'
            : 'Отправлен куратором вашей компании';

        case 'expired':
          return 'Пройден, данные устарели';

        default:
          return '';
      }
    },
    testDate() {
      switch (this.test.status) {
        case 'passed':
          return formatDate(this.test.finishedAt);

        case 'sent':
          return formatDate(this.test.sentAt);

        case 'expired':
          return formatDate(this.test.finishedAt);

        default:
          return '';
      }
    },
  },
};
</script>
